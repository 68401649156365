import React, { useState, useEffect } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { registerQrCode } from '../../../services'
import { CameraScanner, Modal, Loading } from '../../shared'
import { intl, theme, STAGE } from '../../../lib'
import { QrCodeIcon } from '../../icons'
import {
  Common_SessionExpired,
  Common_TechnicalIssues,
  Header_QrCode,
  NativeTranslations_RegisterQRCode_Description,
  NativeTranslations_RegisterQRCode_InvalidCode,
  NativeTranslations_RegisterQRCode_LoginFailed,
  NativeTranslations_RegisterQRCode_LoginNotPossible,
  NativeTranslations_RegisterQRCode_Success
} from '../../../translations/messages'

const resultMessages = {
  '400': intl.formatMessage(NativeTranslations_RegisterQRCode_InvalidCode),
  '401': intl.formatMessage(Common_SessionExpired),
  '520': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginFailed),
  '503': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginNotPossible),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const QRCodeButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await registerQrCode(value)
        setRequestStatus('success')
      } catch (error: any) {
        logError(new Error('Failed to register QR code'), error)
        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <div className="absolute top-0 left-0 size-full bg-[rgba(255,255,255,0.8)] flex flex-col items-center justify-center text-center">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2 className="mt-5">{intl.formatMessage(NativeTranslations_RegisterQRCode_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2 className="mt-5">{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </div>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_QR_CODE ? (
    <>
      <button
        className="card shadow-2xl p-0 rounded-full flex flex-col items-center justify-center h-[100px] w-[100px]"
        onClick={toggleCamera}
      >
        <QrCodeIcon color="black" size="2.3rem" />
        <small className="font-light">{intl.formatMessage(Header_QrCode)}</small>
      </button>

      {showCamera && (
        <Modal isOpen onClose={toggleCamera}>
          <div className="drawer">
            {requestStatus !== 'initial' && renderFeedback()}

            <CameraScanner
              description={intl.formatMessage(NativeTranslations_RegisterQRCode_Description)}
              onRead={onRead}
            />
          </div>
        </Modal>
      )}
    </>
  ) : null
}

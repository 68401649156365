import React from 'react'
import { intl, languages, langHasIntlSupport } from '../../lib'
import { LanguageIcon } from '../icons'
import { LanguagePicker_Language } from '../../translations/messages'

const supportedLangs = Object.keys(languages).filter(langHasIntlSupport)

const setNewLanguage = async (newLang: string) => {
  if (newLang) {
    try {
      window.localStorage.setItem('activeLanguage', newLang)
    } catch (error) {
      // Don't care
    }

    window.location.reload()
  }
}

export const LanguagePickerIconButton = () =>
  supportedLangs.length > 0 ? (
    <div className="relative flex items-center overflow-hidden">
      <LanguageIcon className="pointer-none pointer-events-none absolute top-0 right-0" />

      <select
        style={{ background: 'none' }}
        className="w-[1-6rem] h-[1.6rem] appearance-none border-none cursor-pointer text-transparent"
        aria-label={intl.formatMessage(LanguagePicker_Language)}
        value={intl.locale}
        onChange={evt => setNewLanguage(evt.target.value)}
      >
        {supportedLangs.map(langCode => (
          <option className="text-[initial]" key={langCode} value={langCode}>
            {intl.formatMessage(languages[langCode].nameTranslation)}
          </option>
        ))}
      </select>
    </div>
  ) : null

export const LanguagePickerButton = () =>
  supportedLangs.length > 0 ? (
    <div className="relative flex items-center p-6 space-x-4">
      <LanguageIcon className="pointer-none" />
      <span>{intl.formatMessage(LanguagePicker_Language)}</span>

      <select
        style={{ background: 'none' }}
        className="absolute top-0 left-0 h-full w-full outline-none appearance-none border-none cursor-pointer text-transparent"
        aria-label={intl.formatMessage(LanguagePicker_Language)}
        value={intl.locale}
        onChange={evt => setNewLanguage(evt.target.value)}
      >
        {supportedLangs.map(langCode => (
          <option className="text-[initial]" key={langCode} value={langCode}>
            {intl.formatMessage(languages[langCode].nameTranslation)}
          </option>
        ))}
      </select>
    </div>
  ) : null

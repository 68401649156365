import React, { useContext, useMemo } from 'react'
import { LocationCategoryInfo } from './LocationCategoryInfo'
import { theme, intl } from '../../lib'
import { LocationsContext } from './LocationsContext'
import {
  LocationSearch_FilterOnReturnPoint,
  CollectionPointTypes_RVM_Title,
  CollectionPointTypes_DEPOT_Title,
  CollectionPointTypes_OTC_Title,
  CollectionPointTypes_DONATION_STATION_Title
} from '../../translations/messages'

const categoryTitleMap = {
  RVM: CollectionPointTypes_RVM_Title,
  DEPOT: CollectionPointTypes_DEPOT_Title,
  OTC: CollectionPointTypes_OTC_Title,
  DONATION_STATION: CollectionPointTypes_DONATION_STATION_Title
}

export const LocationFilter = () => {
  const { activeFilters, filterMarkers, locations } = useContext(LocationsContext)

  // Expensive computation, hence useMemo
  const locationCategories = useMemo(() => {
    return locations.reduce((acc: LocationCategory[], location) => {
      if (location.category && !acc.includes(location.category)) {
        acc.push(location.category)
      }
      return acc
    }, [])
  }, [locations])

  if (locationCategories.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col space-y-3">
      <p className="flex items-center justify-between">
        <p>{intl.formatMessage(LocationSearch_FilterOnReturnPoint)}</p>
        <LocationCategoryInfo locationCategories={locationCategories} />
      </p>

      {locationCategories.map(category => (
        <label htmlFor={category} key={category}>
          <div className="card flex items-center justify-between px-5 py-2">
            <img src={theme.images.locationCategory[category].icon} alt={`${category} icon`} />

            <span>{intl.formatMessage(categoryTitleMap[category])}</span>

            <input
              id={category}
              type="checkbox"
              className="checkbox min-w-[1.3rem]"
              aria-label={`Filter by ${category}`}
              onChange={() => filterMarkers(category)}
              checked={activeFilters.includes(category)}
            />
          </div>
        </label>
      ))}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useLocationDetailsQuery } from '../../hooks'
import { LocationCategory } from './LocationCategory'
import { LocationStatus } from './LocationStatus'
import { getItemFromWebStorage, setItemInWebStorage, intl } from '../../lib'
import { StarIcon, LocationIcon, TimeIcon, InfoIcon } from '../icons'
import { Loading, Modal } from '../shared'
import { isOnNative, openAppUrl } from '../../services'
import { LocationDetails_Directions, LocationDetails_MakeFavorite } from '../../translations/messages'

export const LocationDetails = () => {
  const navigate = useNavigate()
  const { openedLocationId } = useParams()
  const [favoriteLocationId, setFavoriteLocationId] = useState('')
  const { data } = useLocationDetailsQuery(openedLocationId!)

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  const _openDirections = () => {
    const { latitude, longitude } = data || {}
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`

    if (isOnNative()) {
      openAppUrl(url)
    } else {
      window.open(url)
    }
  }

  const _toggleFavoriteLocation = () => {
    const isAlreadyFavoriteLocation = favoriteLocationId === openedLocationId
    isAlreadyFavoriteLocation ? _updateFavoriteLocation('') : _updateFavoriteLocation(openedLocationId)
  }

  const _updateFavoriteLocation = locationId => {
    setItemInWebStorage('favoriteLocationId', locationId)
    setFavoriteLocationId(locationId)
  }

  const isFavoriteLocation = openedLocationId === favoriteLocationId

  return (
    <Modal onClose={() => navigate('..', { replace: true })} isOpen>
      <div className="drawer">
        <div className="max-w-[var(--max-page-width)] mx-auto p-10 space-y-8">
          {!data ? (
            <div className="flex justify-center my-32">
              <Loading className="mx-auto" />
            </div>
          ) : (
            <>
              <h1 className="text-2xl mb-5">{data.name}</h1>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <div className="min-w-max">
                    <LocationIcon />
                  </div>
                  <p>{data.address}</p>
                </div>

                {data.readableOpeningHours && (
                  <div className="flex items-center space-x-2">
                    <div className="min-w-max">
                      <TimeIcon />
                    </div>
                    <p>{data.readableOpeningHours}</p>
                  </div>
                )}

                {data.info && (
                  <div className="flex items-center space-x-2">
                    <div className="min-w-max">
                      <InfoIcon />
                    </div>
                    <p>{data.info}</p>
                  </div>
                )}
              </div>
              <LocationCategory locationCategory={data.category} />

              {data.status && <LocationStatus locationStatuses={data.status} />}

              <div className="flex flex-col items-center space-y-3">
                <button className="btn w-full flex justify-between items-center" onClick={_toggleFavoriteLocation}>
                  <StarIcon fillColor={isFavoriteLocation ? 'rgb(var(--tomra-yellow))' : ''} />
                  <p>{intl.formatMessage(LocationDetails_MakeFavorite)}</p>
                  <div />
                </button>

                <button className="btn primary w-full" onClick={_openDirections}>
                  {intl.formatMessage(LocationDetails_Directions)}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

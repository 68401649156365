import React, { FunctionComponent } from 'react'
import { ArrowDownIcon } from '../../icons'

type Props = {
  label: string
  options: FilterOptionsType[]
  defaultValue: string
  onChange: (value: string) => void
}

export const Filter: FunctionComponent<Props> = ({ label, options, defaultValue, onChange }) => {
  return (
    <div className="relative w-full">
      <select
        aria-label={label}
        value={defaultValue}
        onChange={({ target: { value } }) => onChange(value)}
        className="btn appearance-none truncate text-sm font-bold pl-5 pr-10 w-full"
      >
        <option value="">{label}</option>
        {options.map((option, i) => (
          <option key={`opt-${i}`} value={option.value}>
            {option.key}
          </option>
        ))}
      </select>

      <div className="absolute top-1/2 right-5 -translate-y-1/2 z-10 pointer-events-none">
        <ArrowDownIcon />
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { useProfileQuery, useBadgesQuery, usePayoutMethodMutation } from '../../hooks/query-hooks'
import { Header } from './header/Header'
import { ContainerScannerButton } from './ContainerScannerButton'
import { FindLocation } from './favoriteLocation/FindLocation'
import { MyLocation } from './favoriteLocation/MyLocation'
import { AustraliaCampaignCard } from './temporary/AustraliaCampaignCard'
import { NswInMytomraBanner } from './temporary/NswInMytomraBanner'
import { Loading } from '../shared'
import { hasWebCameraScannerSupport } from '../../services'
import { pushMessage } from '../AppMessages'
import { VoucherIcon, RecycleBottleIcon, StarIcon, HeartIcon } from '../icons'
import {
  getItemFromWebStorage,
  countryStateMap,
  intl,
  getQueryParameterByName,
  VIEWER_COUNTRY_STATE,
  APP_NAME
} from '../../lib'
import {
  Common_Achievements,
  Common_Vouchers,
  Common_Charities,
  Common_UpdatePayoutFailure,
  Common_Refunds
} from '../../translations/messages'
import { ScheduledDeletePage } from './ScheduledDeletePage'

export const HomePage = () => {
  const { mutate } = usePayoutMethodMutation()
  const { data: profile } = useProfileQuery()
  const { data: badges } = useBadgesQuery()
  const [favoriteLocationId, setFavoriteLocationId] = useState('')
  const { features } = countryStateMap[VIEWER_COUNTRY_STATE]

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  useEffect(() => {
    try {
      const encodedpayload = getQueryParameterByName('encodedpayload')

      if (!encodedpayload) {
        return
      }

      const { type, recipient, errorMessage } = JSON.parse(atob(encodedpayload))

      if (errorMessage) {
        throw new Error(errorMessage)
      }

      mutate({ type, recipient, method: 'POST' })
      window.history.replaceState({}, document.title, window.location.pathname)
    } catch (error: any) {
      pushMessage({ formattedMessage: intl.formatMessage(Common_UpdatePayoutFailure), type: 'danger', ttl: 5000 })
      logError(new Error('Failed to update payout'), error)
    }
  }, [mutate])

  if (!(profile && badges)) {
    return <Loading className="centerAbsolute" />
  }

  if (profile.scheduledDeleteAt) {
    return <ScheduledDeletePage scheduledDeleteAt={profile.scheduledDeleteAt} />
  }

  return (
    <div className="animate-fadeIn">
      <Header barcode={profile.barcode} />

      <div className="p-5 max-w-[var(--max-page-width)] mx-auto flex flex-col space-y-5">
        {profile.activeCountryState?.includes('AU-') && APP_NAME === 'MYTOMRA' && <NswInMytomraBanner />}

        <AustraliaCampaignCard />

        <ul className="card-list">
          <li>{favoriteLocationId ? <MyLocation favoriteLocationId={favoriteLocationId} /> : <FindLocation />}</li>

          {features.HAS_CHARITY && (
            <li>
              <Link to="/charities" className="active">
                <HeartIcon />
                <span>{intl.formatMessage(Common_Charities)}</span>
              </Link>
            </li>
          )}

          <li>
            <Link to="/vouchers" className="active">
              <VoucherIcon />
              <span>{intl.formatMessage(Common_Vouchers)}</span>
              {badges.numberOfUnredeemedVouchers !== 0 && (
                <p className="badge bg-mint-green">{badges.numberOfUnredeemedVouchers}</p>
              )}
            </Link>
          </li>

          <li>
            <Link to="/activity" className="active">
              <RecycleBottleIcon />
              <span>{intl.formatMessage(Common_Refunds)}</span>
              {badges.hasPayoutError && <span className="badge">!</span>}
            </Link>
          </li>

          <li>
            <Link to="/achievements" className="active">
              <StarIcon />
              <span>{intl.formatMessage(Common_Achievements)}</span>
            </Link>
          </li>

          {hasWebCameraScannerSupport() && (
            <li>
              <ContainerScannerButton countryStateFeatures={features} />
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

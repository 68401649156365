import React from 'react'
import { Link } from 'react-router-dom'
import { useLocationDetailsQuery } from '../../../hooks'
import { LocationStatus } from '../../locations/LocationStatus'
import { FavoriteLocationIcon } from '../../icons'

const everyTwentySeconds = 20 * 1000

type Props = {
  favoriteLocationId: string
}

export const MyLocation = ({ favoriteLocationId }: Props) => {
  const { data: location } = useLocationDetailsQuery(favoriteLocationId, everyTwentySeconds)

  return (
    <Link to={`locations/${favoriteLocationId}`}>
      {!location ? (
        <div className="p-5 space-y-5">
          <div className="flex justify-between items-center">
            <div className="skeleton-circle size-10" />
            <div className="skeleton-heading w-48" />
            <div className="skeleton-paragraph size-5" />
          </div>

          <div className="skeleton-heading w-full" />
        </div>
      ) : (
        <div className="flex flex-col w-full">
          <div className="w-full flex justify-between items-center space-x-5">
            <FavoriteLocationIcon />

            <small>
              <p>{location.name}</p>
              <p className="font-light">{location.readableOpeningHours}</p>
            </small>

            <div />
          </div>

          {location.status && <LocationStatus locationStatuses={location.status} />}
        </div>
      )}
    </Link>
  )
}

import React from 'react'
import { ActivityRow } from './ActivityRow'

type Props = {
  title: string
  activities: RefundType[]
}

export const ActivitiesSection = ({ title, activities }: Props) => (
  <div>
    <header className="flex justify-end items-center mt-10 space-x-3">
      <h2 className="font-bold">{title}</h2>
      <div className="h-[2px] bg-black flex-grow" />
    </header>

    {activities.map(activity => (
      <ActivityRow activity={activity} key={activity.id + Math.random()} />
    ))}
  </div>
)

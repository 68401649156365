import React from 'react'
import { useNavigate } from 'react-router-dom'
import { type UseMutationResult } from '@tanstack/react-query'
import { Loading } from '../../shared'
import { intl } from '../../../lib'
import {
  Common_BrowseCharities,
  PayoutSettings_Donation_Description,
  Common_EditPayout,
  Common_UsePrevious,
  Common_CharityCampaign_PayoutDisclaimer
} from '../../../translations/messages'

type Props = {
  active: PayoutMethodType['active']
  savedConfiguration: PayoutMethodType['savedConfiguration']
  updateQuery: UseMutationResult<any, Error, PayoutUpdateType, unknown>
}

export const Donation = ({ active, savedConfiguration, updateQuery }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>

      <span>{intl.formatMessage(PayoutSettings_Donation_Description)}</span>

      <span>{intl.formatMessage(Common_CharityCampaign_PayoutDisclaimer)}</span>

      <div className="flex flex-col space-y-5">
        {savedConfiguration && !active && (
          <button
            className="btn primary flex justify-center items-center"
            aria-disabled={updateQuery.isPending}
            disabled={updateQuery.isPending}
            onClick={() => updateQuery.mutate({ type: 'DONATION', method: 'PUT' })}
          >
            {updateQuery.isPending ? (
              <Loading />
            ) : (
              <div>
                <p className="leading-5">{intl.formatMessage(Common_UsePrevious)}</p>
                <p className="text-sm">{`(${savedConfiguration.charityName} - ${savedConfiguration.campaignName})`}</p>
              </div>
            )}
          </button>
        )}

        <button className="btn" onClick={() => navigate('/charities')}>
          {intl.formatMessage(Common_BrowseCharities)}
        </button>
      </div>
    </>
  )
}

import React from 'react'
import { achievementMap } from '../lib/achievementMap'
import { intl, theme } from '../../../lib'
import {
  Achievement_Done,
  Achievement_NotStarted,
  Achievement_Remaining,
  Achievements_Name_Description_Unknown
} from '../../../translations/messages'

type Props = {
  achievement: AchievementType
  large?: boolean
  showUnknownBadge?: boolean
}

const unknownAchievement = {
  badge: '',
  messages: { title: 'Unknown achievement', description: Achievements_Name_Description_Unknown }
}

export const Achievement = ({ achievement, large, showUnknownBadge }: Props) => {
  const { achievementName, progress, currentLevel, levels, currency } = achievement
  const achievementConfig = achievementMap[achievementName] || {}

  const { badge, messages } =
    achievementMap[achievementName] === undefined
      ? unknownAchievement
      : achievementConfig[currentLevel] || achievementConfig[0]

  const _getTitleAndDescription = () => {
    return {
      title: messages.title,
      description: intl.formatMessage(messages.description, {
        levelRequirement: levels[currentLevel],
        currency
      })
    }
  }

  const _renderProgressBar = () => {
    const levelRequirement = levels[currentLevel]
    const remaining = Math.ceil(levelRequirement - progress)
    let percentage = Math.floor((progress / levelRequirement) * 100)

    if (percentage > 100) percentage = 100
    if (percentage < 5) percentage = 5

    const bgColorClass = percentage === 100 ? 'bg-mint-green' : percentage > 30 ? 'bg-yellow' : 'bg-warm-red'
    const barRoundover = percentage >= 95 ? 'rounded-full' : 'rounded-l-full'

    return (
      <div className="relative flex justify-center border-2 border-black rounded-full w-full max-w-[var(--max-page-width)]">
        <p className="z-10">
          {percentage === 100
            ? intl.formatMessage(Achievement_Done)
            : levelRequirement - progress === levelRequirement
              ? intl.formatMessage(Achievement_NotStarted)
              : intl.formatMessage(Achievement_Remaining, {
                  remainingValue: currency ? `${remaining} ${currency}` : remaining
                })}
        </p>

        <div
          className={`absolute top-0 left-0 h-full ${barRoundover} ${bgColorClass}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    )
  }

  const { title, description } = _getTitleAndDescription()

  return large ? (
    <div className="flex flex-col items-center space-y-3">
      <img
        className="max-w-32"
        src={showUnknownBadge ? theme.images.achievements.unknownBadge : badge}
        onError={evt => {
          const target = evt.target as HTMLImageElement
          target.src = theme.images.achievements.defaultBadge
        }}
        alt={achievementName + currentLevel}
      />

      <h2>{title}</h2>
      <small className="my-2">
        <b>{description}</b>
      </small>
      {_renderProgressBar()}
    </div>
  ) : (
    <div className="flex items-center p-5">
      <img
        className="max-w-24"
        src={showUnknownBadge ? theme.images.achievements.unknownBadge : badge}
        onError={evt => {
          const target = evt.target as HTMLImageElement
          target.src = theme.images.achievements.defaultBadge
        }}
        alt={achievementName + currentLevel}
      />

      <div className="flex-1 flex flex-col ml-5">
        <h2>{title}</h2>
        <small className="my-2">
          <b>{description}</b>
        </small>
        {_renderProgressBar()}
      </div>
    </div>
  )
}

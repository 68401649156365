import React from 'react'
import { Link } from 'react-router-dom'
import { Loading } from '../shared'
import { useAchievementsQuery } from '../../hooks'
import { theme, VIEWER_COUNTRY_STATE, countryStateMap } from '../../lib'
import { Achievement } from './common'

export const AllAchievements = () => {
  const { data } = useAchievementsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const maybeRemoveDonationAchievements = (achievement: AchievementType) => {
    if (countryStateMap[VIEWER_COUNTRY_STATE].features.HAS_CHARITY) return true
    else return !achievement.achievementName.includes('_DONATION_')
  }

  const existingInTheme = (achievement: AchievementType) => {
    return Object.keys(theme.achievements).some(name => name.includes(achievement.achievementName))
  }

  return (
    <div className="flex flex-col p-5 max-w-[var(--max-page-width)] mx-auto">
      {data.allAchievements
        .filter(existingInTheme)
        .filter(maybeRemoveDonationAchievements)
        .map(achievement => {
          return (
            <Link
              to={`../${achievement.achievementName}`}
              key={achievement.achievementName + achievement.currentLevel}
              className="card mt-5"
            >
              <Achievement
                achievement={{
                  ...achievement,
                  // small hack since currentLevel is index based and should really stop at 3 for the last level
                  currentLevel: achievement.currentLevel === 4 ? 3 : achievement.currentLevel
                }}
              />
            </Link>
          )
        })}
    </div>
  )
}

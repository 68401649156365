import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '../icons'
import { Common_Back } from '../../translations/messages'
import { intl } from '../../lib'

type Props = {
  to?: string
  withWhiteBackground?: boolean
}

export const BackButton = ({ to, withWhiteBackground }: Props) => {
  const navigate = useNavigate()

  const withBackgroundStyles = withWhiteBackground ? `bg-white p-5 rounded-full` : ''

  return (
    <button
      className={`flex items-center absolute top-5 left-5 z-10 ${withBackgroundStyles}`}
      onClick={() => (to ? navigate(to) : navigate(-1))}
    >
      <ArrowLeftIcon />
      <h2 className="leading-3 ml-3 text-lg">{intl.formatMessage(Common_Back)}</h2>
    </button>
  )
}

import React, { useState } from 'react'
import { Loading } from '../../shared/common'
import { intl } from '../../../lib'
import { ActivitiesPage_SendTaxReceipts } from '../../../translations/messages'
import { ExportIcon } from '../../icons'
import { TaxReceiptDrawer } from './TaxReceiptDrawer'
import { usePayoutMethodsQuery } from '../../../hooks'

export const SendDonationTaxReceipts = () => {
  const { data } = usePayoutMethodsQuery()
  const [showReceiptDrawer, setShowReceiptDrawer] = useState<boolean>(false)

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const donationPayoutAvailable = data?.find(method => method.type === 'DONATION')

  return donationPayoutAvailable ? (
    <>
      <button
        className="btn-icon"
        aria-label={intl.formatMessage(ActivitiesPage_SendTaxReceipts)}
        onClick={() => setShowReceiptDrawer(true)}
      >
        <ExportIcon />
      </button>

      <TaxReceiptDrawer show={showReceiptDrawer} onClose={() => setShowReceiptDrawer(false)} />
    </>
  ) : null
}

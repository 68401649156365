import React, { useState, useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import GoogleMap from 'google-map-react'
import { logError } from '@tomra/datadog-browser-logging'
import { BackButton, Loading, Modal } from '../shared'
import { SearchIcon, UserLocationIcon, LocationListIcon } from '../icons'
import { pushMessage } from '../AppMessages'
import { LocationSearch, LocationList } from './'
import { findUserLocation } from '../../services'
import { countryStateMap, intl, VIEWER_COUNTRY_STATE, GOOGLE_MAPS_API_KEY } from '../../lib'
import { LocationsProvider, LocationsContext } from './LocationsContext'
import {
  Location_GoToYourLocation,
  LocationList_Title,
  LocationMarkers_FailedToLoadKml,
  LocationSearch_Title,
  LocationsPage_MapLoadingFailed
} from '../../translations/messages'

const roundButtonClass = 'bg-white rounded-full p-4 shadow-md'

const fallbackUserLocation = { lat: -33.83, lng: 151 } // Sydney

const LocationsPageContainer = () => {
  const { openedLocationId } = useParams()
  const [mapLoadingStatus, setMapLoadingStatus] = useState<RequestStatusType>('loading')
  const [showSearchDrawer, setShowSearchDrawer] = useState(false)
  const [showLocationListDrawer, setShowLocationListDrawer] = useState(false)
  const [userLocation, setUserLocation] = useState<CoordinatesType>()
  const [userLocationMarker, setUserLocationMarker] = useState<google.maps.Marker>()
  const [allowGeolocationInterval, setAllowGeolocationInterval] = useState(false)
  const { mapInstance, initMapMarkers } = useContext(LocationsContext)
  const mapCenter = countryStateMap[VIEWER_COUNTRY_STATE].coordinates

  const findAndSetUserLocation = (map?: google.maps.Map, maps?: typeof google.maps) => {
    findUserLocation()
      .then(result => {
        setUserLocation(result)
        setAllowGeolocationInterval(true)

        if (map && maps) {
          map.setCenter(result)

          setUserLocationMarker(
            new maps.Marker({
              map: map,
              position: result,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#4286f5',
                fillOpacity: 1,
                strokeColor: 'white',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 13
              }
            })
          )
        }
      })
      .catch(() => {
        setAllowGeolocationInterval(false)
      })
  }

  const onMapLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    const didLoadSuccessfully = map !== null && maps !== null

    if (didLoadSuccessfully) {
      setMapLoadingStatus('success')

      findAndSetUserLocation(map, maps)

      initMapMarkers(map, maps).catch(error => {
        pushMessage({
          formattedMessage: intl.formatMessage(LocationMarkers_FailedToLoadKml),
          type: 'danger'
        })

        logError(new Error('Failed to load/set map locations'), error)
      })
    } else {
      setMapLoadingStatus('failed')
    }
  }

  useEffect(() => {
    let intervalRef

    if (allowGeolocationInterval) {
      intervalRef = setInterval(() => {
        findUserLocation()
          .then(result => {
            userLocationMarker?.setPosition(result)
            setUserLocation(result)
          })
          .catch(() => {
            clearInterval(intervalRef)
            setAllowGeolocationInterval(false)
          })
      }, 5000)
    }

    return () => clearInterval(intervalRef)
  }, [allowGeolocationInterval, userLocationMarker])

  return (
    <>
      <BackButton to="/" withWhiteBackground />

      <div className="relative h-screen" data-testid="locationsMap">
        <GoogleMap
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultZoom={11}
          center={mapCenter || fallbackUserLocation}
          options={mapsSdk => ({
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            zoomControlOptions: {
              position: mapsSdk.ControlPosition.RIGHT_CENTER
            },
            keyboardShortcuts: false
          })}
          onGoogleApiLoaded={onMapLoaded}
          yesIWantToUseGoogleMapApiInternals={true}
        />

        {mapLoadingStatus === 'loading' ? (
          <div data-testid="loading">
            <Loading className="centerAbsolute" />
          </div>
        ) : mapLoadingStatus === 'failed' ? (
          <div className="centerAbsolute alert danger">{intl.formatMessage(LocationsPage_MapLoadingFailed)}</div>
        ) : (
          <>
            <div className="absolute bottom-5 right-5 flex flex-col space-y-3">
              <button
                className={roundButtonClass}
                onClick={() => setShowSearchDrawer(true)}
                aria-label={intl.formatMessage(LocationSearch_Title)}
              >
                <SearchIcon size="2rem" />
              </button>

              <button
                className={roundButtonClass}
                onClick={() => setShowLocationListDrawer(true)}
                aria-label={intl.formatMessage(LocationList_Title)}
              >
                <LocationListIcon size="2rem" />
              </button>

              <button
                className={roundButtonClass}
                onClick={() => mapInstance?.setCenter(userLocation!)}
                disabled={!userLocation}
                aria-disabled={!userLocation}
                aria-label={intl.formatMessage(Location_GoToYourLocation)}
              >
                <UserLocationIcon size="2rem" />
              </button>
            </div>

            <Modal onClose={() => setShowSearchDrawer(false)} isOpen={showSearchDrawer}>
              <div className="drawer">
                <LocationSearch closeDrawer={() => setShowSearchDrawer(false)} />
              </div>
            </Modal>

            <Modal
              onClose={() => setShowLocationListDrawer(false)}
              isOpen={showLocationListDrawer && !openedLocationId}
            >
              <div className="drawer max-h-[90vh]">
                <LocationList />
              </div>
            </Modal>

            <Outlet />
          </>
        )}
      </div>
    </>
  )
}

export const LocationsPage = () => (
  <LocationsProvider>
    <LocationsPageContainer />
  </LocationsProvider>
)

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { updateProfile } from 'firebase/auth'
import { pushMessage } from '../AppMessages'
import { updateUser } from '../../services'
import { intl, theme, FIREBASE } from '../../lib'
import { BackButton, CountryStatePicker, Modal, InfoTooltip, Loading, TextField } from '../shared'
import { EarthIcon, TrashcanIcon, LockIcon } from '../icons'
import { NewsLetterSetting } from './NewsLetterSetting'
import { ChangePassword } from './ChangePassword'
import { LanguagePickerButton } from '../login/LanguagePicker'
import {
  AccountPage_ChangeLocation,
  Auth_FirstNameDisclaimer,
  Auth_Name,
  Common_DeleteAccount,
  Common_EditProfile,
  Common_Update,
  Common_Email,
  Common_UpdateFailed,
  Common_ChangePassword,
  UpdateEmail_CheckEmailUpdate
} from '../../translations/messages'

export const AccountPage = () => {
  const navigate = useNavigate()
  const currentDisplayName = FIREBASE.auth.currentUser?.displayName || ''
  const currentEmail = FIREBASE.auth.currentUser?.email || ''
  const [newDisplayName, setNewDisplayName] = useState(currentDisplayName)
  const [newEmail, setNewEmail] = useState(currentEmail)
  const [newEmailValid, setNewEmailValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showSelectLocationDrawer, setShowSelectLocationDrawer] = useState(false)
  const [showPasswordDrawer, setShowPasswordDrawer] = useState(false)
  const [hasPasswordLoginEnabled, setHasPasswordLoginEnabled] = useState(false)
  const emailHasChanged = newEmail !== currentEmail && newEmailValid
  const displayNameHasChanged = currentDisplayName !== newDisplayName
  const saveButtonDisabled = !(displayNameHasChanged || emailHasChanged) || isLoading

  useEffect(() => {
    FIREBASE.auth.currentUser?.getIdTokenResult(true).then(token => {
      setHasPasswordLoginEnabled(token.claims.passwordLoginEnabled)
      setNewEmail(FIREBASE.auth.currentUser?.email || '')
    })
  }, [])

  const _performProfileUpdateRequest = async (evt: any) => {
    evt.preventDefault()

    const payload: {
      firstName: string
      email?: string
    } = {
      firstName: newDisplayName,
      email: newEmail
    }

    // Don't want to include email in payload if not changed, as that will trigger a verify email
    if (newEmail === currentEmail) {
      delete payload.email
    }

    try {
      setIsLoading(true)
      await updateUser(payload).run()

      // updateUser() above also does this, but FIREBASE.currentUser wont update until next page load, so we do it here
      await updateProfile(FIREBASE.auth.currentUser!, { displayName: payload.firstName })

      if (newEmail !== currentEmail) {
        pushMessage({
          formattedMessage: intl.formatMessage(UpdateEmail_CheckEmailUpdate),
          type: 'success',
          ttl: 5000
        })
      }
    } catch (error: any) {
      pushMessage({
        formattedMessage: intl.formatMessage(Common_UpdateFailed),
        type: 'danger',
        ttl: 5000
      })

      logError(new Error('Failed to update user'), error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="animate-fadeIn">
      <BackButton to="/" />

      <div className="flex flex-col p-5 space-y-16 mx-auto max-w-[var(--max-page-width)]">
        <Modal onClose={() => setShowSelectLocationDrawer(!showSelectLocationDrawer)} isOpen={showSelectLocationDrawer}>
          <div className="drawer">
            <CountryStatePicker />
          </div>
        </Modal>

        <Modal onClose={() => setShowPasswordDrawer(!showPasswordDrawer)} isOpen={showPasswordDrawer}>
          <div className="drawer">
            <ChangePassword email={newEmail} closeDrawer={() => setShowPasswordDrawer(!showPasswordDrawer)} />
          </div>
        </Modal>

        <h1 className="text-3xl ml-5 mt-16">{intl.formatMessage(Common_EditProfile)}</h1>

        <form onSubmit={_performProfileUpdateRequest} className="my-5 space-y-5 w-full">
          <div className="relative">
            <TextField
              type="text"
              onChange={value => setNewDisplayName(value)}
              value={newDisplayName}
              label={intl.formatMessage(Auth_Name)}
            />

            <div className="absolute top-1/2 right-5">
              <InfoTooltip
                content={intl.formatMessage(Auth_FirstNameDisclaimer, { appName: theme.name })}
                position="right"
              />
            </div>
          </div>

          <TextField
            onChange={(value, valid) => {
              setNewEmail(value)
              setNewEmailValid(valid)
            }}
            value={newEmail}
            label={intl.formatMessage(Common_Email)}
            type="email"
            ignoreWhitespace
            required
          />

          <button
            className="btn w-full mt-3"
            type="submit"
            aria-label={intl.formatMessage(Common_Update)}
            disabled={saveButtonDisabled}
          >
            {isLoading ? <Loading /> : intl.formatMessage(Common_Update)}
          </button>
        </form>

        <ul className="card-list">
          <li>
            <NewsLetterSetting />
          </li>

          <li>
            <LanguagePickerButton />
          </li>

          {hasPasswordLoginEnabled && (
            <li>
              <button className="active" onClick={() => setShowPasswordDrawer(true)}>
                <LockIcon />
                <p>{intl.formatMessage(Common_ChangePassword)}</p>
              </button>
            </li>
          )}

          {theme.showCountryPicker && (
            <li>
              <button className="active" onClick={() => setShowSelectLocationDrawer(true)}>
                <EarthIcon />
                <p>{intl.formatMessage(AccountPage_ChangeLocation)}</p>
              </button>
            </li>
          )}

          <li>
            <button className="active" onClick={() => navigate('/delete')}>
              <TrashcanIcon />
              <p>{intl.formatMessage(Common_DeleteAccount)}</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

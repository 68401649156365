import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { feedbackMessages } from './feedbackMessages'
import { TextField, Loading, BackButton } from '../shared'
import { LockIcon, ErrorIcon } from '../icons'
import { FIREBASE, intl, getItemFromWebStorage, removeItemFromWebStorage } from '../../lib'
import { updateUser, updateMarketingsubscription } from '../../services'
import {
  Auth_AlmostThere,
  Auth_ContinueWithPassword,
  Auth_PasswordRequirements,
  Common_Continue,
  Common_Password
} from '../../translations/messages'

export const PasswordLoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [passwordValue, setPasswordValue] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const performCredentialSignIn = async evt => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      setFeedbackMessage('')

      const { email: emailState, name: nameState, acceptedNewsletter: acceptedNewsletterState } = location.state || {}
      const email = emailState || (await getItemFromWebStorage('emailForSignIn')) || ''
      const name = nameState || (await getItemFromWebStorage('nameForSignIn')) || ''
      const acceptedNewsletter = acceptedNewsletterState || (await getItemFromWebStorage('newsletterForSignIn')) || ''

      if (!email) {
        navigate('/login/confirm-email' + window.location.search, { replace: true })
        return
      }

      const { user } = await signInWithEmailAndPassword(FIREBASE.auth, location.state?.email, passwordValue)

      if (name) {
        await updateUser({ firstName: name })
          .run()
          .catch(error => logError(new Error('Failed to set name on login'), error))

        // updateUser() above also does this, but FIREBASE.currentUser wont update until next page load, so we force it here
        await updateProfile(user, { displayName: name }).catch(error =>
          logError(new Error('Failed to set name on login'), error)
        )
      }

      if (acceptedNewsletter) {
        updateMarketingsubscription('SUBSCRIBED')
          .run()
          .catch(error => logError(new Error('Failed to set newsletter subscription on login'), error))
      }

      removeItemFromWebStorage('nameForSignIn')
      removeItemFromWebStorage('newsletterForSignIn')

      // Just to clear state from url
      navigate('/', { replace: true })
    } catch (error: any) {
      setRequestStatus('failed')
      setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
      logError(new Error('Failed to log in with credentials'), error)
    }
  }

  if (!location.state?.email) {
    return (
      <>
        <BackButton to="/login" />

        <div className="flex flex-col min-h-[90vh] justify-around">
          <ErrorIcon className="centerMargin" size="5rem" />
          <div className="alert info">{feedbackMessages['missing-email']}</div>
        </div>
      </>
    )
  }

  return (
    <div className="animate-fadeIn">
      <BackButton to="/login" />

      <div className="flex flex-col align- justify-around min-h-[90vh]">
        <LockIcon className="centerMargin" size="5rem" />

        <h1 className="text-3xl">{intl.formatMessage(Auth_AlmostThere)}</h1>

        <p>{intl.formatMessage(Auth_ContinueWithPassword)}</p>

        <form onSubmit={performCredentialSignIn} className="space-y-5">
          {feedbackMessage && <div className="alert danger">{feedbackMessage}</div>}

          <TextField
            onChange={(value, valid) => {
              setPasswordValue(value)
              setPasswordValid(valid)
            }}
            value={passwordValue}
            label={intl.formatMessage(Common_Password)}
            pattern=".{8,}"
            patternErrorMsg={intl.formatMessage(Auth_PasswordRequirements)}
            type="password"
            autoComplete="current-password"
            ignoreWhitespace
            required
          />

          <button className="btn w-full" type="submit" disabled={!passwordValue || !passwordValid}>
            {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Continue)}
          </button>
        </form>
      </div>
    </div>
  )
}

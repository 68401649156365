import React, { useEffect, useState } from 'react'
import { usePayoutMethodMutation } from '../../hooks'
import { Modal } from '../shared'
import { BankTransfer, Donation, PayPal, Voucher } from './options'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import { ActivePayout_Title } from '../../translations/messages'
import { intl } from '../../lib'

const noOp = () => {}

export const PayoutOptionCard = ({ active, savedConfiguration, type, logoUrl }: PayoutMethodType) => {
  const updateQuery = usePayoutMethodMutation()
  const [showDrawer, setShowDrawer] = useState(false)
  const { logo, name, description } = payoutLogoAndTranslationMap[type]
  const isVoucherAndActive = type === 'E_VOUCHER' && active

  useEffect(() => {
    setShowDrawer(false)
  }, [savedConfiguration])

  useEffect(() => {
    if (updateQuery.status === 'success') {
      setShowDrawer(false)
    }
  }, [updateQuery.status])

  const onDrawerClose = () => {
    setShowDrawer(false)
  }

  const componentProps = {
    savedConfiguration,
    active,
    updateQuery
  }

  const typeToComponentMap = {
    BANK_TRANSFER: <BankTransfer {...componentProps} />,
    DONATION: <Donation {...componentProps} />,
    PAYPAL: <PayPal {...componentProps} />,
    E_VOUCHER: <Voucher updateQuery={updateQuery} />
  }

  return (
    <div>
      {active && (
        <div className="card-header">
          <h2>{intl.formatMessage(ActivePayout_Title)}</h2>
        </div>
      )}

      <button
        className="card block w-full p-5 text-left outline-none cursor-pointer"
        onClick={isVoucherAndActive ? noOp : () => setShowDrawer(true)}
        data-testid={name}
      >
        <span className="flex items-center mb-3">
          {logoUrl ? <img className="h-7" aria-hidden={true} src={logoUrl} alt="charity-logo" /> : logo}
          <b className="ml-3">{name}</b>
        </span>

        {savedConfiguration ? (
          <>
            <p>{savedConfiguration[Object.keys(savedConfiguration)[0]]}</p>
            <small>
              <b>{savedConfiguration[Object.keys(savedConfiguration)[1]]}</b>
            </small>
          </>
        ) : (
          <p>{description}</p>
        )}
      </button>

      <Modal onClose={onDrawerClose} isOpen={showDrawer}>
        <div className="drawer">
          <div className="flex flex-col max-w-[var(--max-page-width)] p-10 space-y-8 mx-auto">
            {typeToComponentMap[type] || null}
          </div>
        </div>
      </Modal>
    </div>
  )
}

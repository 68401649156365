import { sharedImages } from '../sharedImages'
import { cdsvicwestImages } from './images'

// Note! These values are only available runtime
export const config = {
  name: 'CDS Vic West',
  domain: 'https://cdsvicwest-app.com',
  showCountryPicker: false,
  appLinks: {
    ios: 'https://itunes.apple.com/au/app/id6450920245',
    android: 'http://play.google.com/store/apps/details?id=com.tomra.digital.cdsvicwest'
  },
  termsOfService: 'https://tomracleanaway.com.au/west-zone-cds-vic-app-tcs/',
  privacyPolicy: 'https://tomracleanaway.com.au/west-zone-cds-vic-app-privacy-policy/',
  faq: `${window.location.origin}/docs/cdsvicwest/faq.html`,
  images: {
    ...sharedImages,
    ...cdsvicwestImages
  },
  setThemeColors: () => {
    const root = document.documentElement

    root.style.setProperty('--colors-theme-primary', 'rgb(255, 130, 0)')
    root.style.setProperty('--colors-theme-primary-light', 'rgb(255, 205, 153)')
    root.style.setProperty('--colors-theme-secondary', 'rgb(255, 130, 0)')
    root.style.setProperty('--colors-theme-secondary-light', 'rgb(255, 205, 153)')
    root.style.setProperty('--colors-theme-tertiary', 'rgb(181, 163, 235)')
    root.style.setProperty('--colors-theme-tertiary-light', 'rgb(225, 218, 247)')
  },
  achievements: {
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1': 'Fledgling recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2': 'Avid recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3': 'Champion recycler',
    'ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4': 'Superstar recycler',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1': 'Local legend',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2': 'Suburb sampler',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3': 'Regional explorer',
    'ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4': 'VIC nomad',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-1': 'Discovering the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-2': 'Exploring the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-3': 'Perfecting the Loop',
    'ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-4': 'Infinite Loop',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1': 'Earnest Earner',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2': 'Earn-trepreneur',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3': 'Moneybags',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4': 'Richest recycler',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1': 'Tree hugger',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2': 'Bushland friend',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3': 'Eco warrior',
    'ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4': 'Earth hero',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-1': 'Feed me!',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-2': 'Machine Maestro',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-3': 'Collection Cyborg',
    'ACHIEVEMENT_HIGHEST_ITEM_COUNT-4': 'Robot Recycler',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1': 'Helping hand',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2': 'Goodhearted giver',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3': 'Dedicated donator',
    'ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4': 'Charitable champ',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-1': 'Goodhearted Giver',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-2': 'Compassionate collector',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-3': 'Determined Donor',
    'ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-4': 'Charity Champion'
  }
}

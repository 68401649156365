import React, { useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { deleteUser, unregisterNotifications } from '../../services'
import { Loading } from '../shared'
import { FIREBASE, removeItemFromWebStorage, intl } from '../../lib'
import {
  Common_Cancel,
  Common_Confirm,
  ConfirmDelete_Confirm,
  ConfirmDelete_ConfirmDelete,
  Common_GeneralError
} from '../../translations/messages'

export const ConfirmDelete = ({ onClose }: { onClose: () => void }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')

  const _deleteUser = async () => {
    try {
      setRequestStatus('loading')
      await unregisterNotifications()
      await deleteUser().run()
      await removeItemFromWebStorage('lastAuthTimestamp')
      await FIREBASE.auth.signOut()
    } catch (error: any) {
      setRequestStatus('failed')
      logError(new Error('Failed to properly delete user'), error)
    }
  }

  return (
    <div className="flex flex-col max-w-[var(--max-page-width)] mx-auto p-10 space-y-5">
      <h1 className="text-3xl">{intl.formatMessage(Common_Confirm)}</h1>

      <p>{intl.formatMessage(ConfirmDelete_Confirm)}</p>

      {requestStatus === 'failed' && <div className="alert danger">{intl.formatMessage(Common_GeneralError)}</div>}

      <div className="flex flex-col space-y-3">
        <button className="btn danger" onClick={_deleteUser} disabled={requestStatus === 'loading'}>
          {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(ConfirmDelete_ConfirmDelete)}
        </button>

        <button className="btn" onClick={onClose} disabled={requestStatus === 'loading'}>
          {intl.formatMessage(Common_Cancel)}
        </button>
      </div>
    </div>
  )
}

import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const BarcodeIcon = (props: Props) => (
  <svg
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 46 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 3L3 36" stroke={props.color || 'black'} strokeWidth="5" strokeLinecap="round" />
    <path d="M16.3333 3L16.3333 36" stroke={props.color || 'black'} strokeWidth="5" strokeLinecap="round" />
    <path d="M29.6666 3L29.6666 36" stroke={props.color || 'black'} strokeWidth="5" strokeLinecap="round" />
    <path d="M43 3L43 36" stroke={props.color || 'black'} strokeWidth="5" strokeLinecap="round" />
  </svg>
)

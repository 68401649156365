import React, { useState, useEffect } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { checkContainerEligibility } from '../../services'
import { CameraScanner, Modal, Loading } from '../shared'
import { intl, theme, VIEWER_COUNTRY_STATE, STAGE } from '../../lib'
import { BarcodeScannerIcon } from '../icons'
import {
  ContainerScanner_Title,
  ContainerScannerButton_EnterManually,
  NativeTranslations_ContainerScanner_Description,
  NativeTranslations_ContainerScanner_Success,
  NativeTranslations_ContainerScanner_Failed,
  Common_Done,
  Common_TechnicalIssues
} from '../../translations/messages'

const resultMessages = {
  '204': intl.formatMessage(NativeTranslations_ContainerScanner_Success),
  '404': intl.formatMessage(NativeTranslations_ContainerScanner_Failed),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const ContainerScannerButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')
  const [barcode, setBarcode] = useState('')
  const barcodeButtonDisabled = barcode.length < 8

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await checkContainerEligibility(value, VIEWER_COUNTRY_STATE)
        setRequestStatus('success')
      } catch (error: any) {
        if (error.status !== 404) {
          logError(new Error('Failed to scan for container eligibility'), error)
        }

        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <div className="absolute top-0 left-0 size-full bg-[rgba(255,255,255,0.8)] flex flex-col items-center justify-center text-center">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2 className="mt-5">{intl.formatMessage(NativeTranslations_ContainerScanner_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2 className="mt-5">{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </div>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_CONTAINER_SCANNER ? (
    <>
      <button className="flex items-center active" onClick={toggleCamera}>
        <div className="flex items-center space-x-3">
          <BarcodeScannerIcon color="black" />
          <span>{intl.formatMessage(ContainerScanner_Title)}</span>
        </div>
      </button>

      {showCamera && (
        <Modal isOpen onClose={toggleCamera}>
          <div className="drawer">
            {requestStatus !== 'initial' && renderFeedback()}
            <div className="absolute bottom-0 w-full p-3 z-10">
              <input
                className="rounded-lg p-2 bg-white w-full border-none mb-3 opacity-80"
                type="text"
                maxLength={13}
                placeholder={intl.formatMessage(ContainerScannerButton_EnterManually)}
                onChange={evt => requestStatus === 'initial' && setBarcode(evt.target.value)}
              />

              <button
                className="btn w-full disabled:opacity-70"
                aria-disabled={barcodeButtonDisabled}
                disabled={barcodeButtonDisabled}
                onClick={() => onRead(barcode)}
              >
                {intl.formatMessage(Common_Done)}
              </button>
            </div>

            <CameraScanner
              description={intl.formatMessage(NativeTranslations_ContainerScanner_Description)}
              onRead={onRead}
            />
          </div>
        </Modal>
      )}
    </>
  ) : null
}

import React from 'react'
import { PayoutOptionCard } from './PayoutOptionCard'
import { usePayoutMethodsQuery } from '../../hooks'
import { BackButton, Loading } from '../shared'
import { intl } from '../../lib'
import {
  PayoutSettings_NoPayoutOptions,
  Common_EditPayout,
  PayoutSettings_AddOrEdit
} from '../../translations/messages'

export const PayoutSettingsPage = () => {
  const { data } = usePayoutMethodsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  return (
    <div className="p-5 max-w-[var(--max-page-width)] mx-auto animate-fadeIn">
      <BackButton />

      {data.length === 0 ? (
        <div className="alert info centerAbsolute">{intl.formatMessage(PayoutSettings_NoPayoutOptions)}</div>
      ) : (
        <>
          <div className="my-12">
            <h1 className="text-3xl mb-7">{intl.formatMessage(Common_EditPayout)}</h1>
            <b>{intl.formatMessage(PayoutSettings_AddOrEdit)}</b>
          </div>

          <div className="flex flex-col space-y-5">
            {data.map((payoutMethod, index) => (
              <PayoutOptionCard {...payoutMethod} key={index} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

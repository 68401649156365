import React, { useState, useEffect } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { registerBagtag } from '../../../services'
import { CameraScanner, Modal, Loading } from '../../shared'
import { intl, theme, STAGE } from '../../../lib'
import { BagIcon } from '../../icons'
import {
  Common_Bagdrop,
  BagdropButton_EnterManually,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser,
  NativeTranslations_RegisterBagdrop_Description,
  NativeTranslations_RegisterBagdrop_InvalidBagtag,
  NativeTranslations_RegisterBagdrop_Success,
  Common_TechnicalIssues,
  Common_Register
} from '../../../translations/messages'

const resultMessages = {
  '400': intl.formatMessage(NativeTranslations_RegisterBagdrop_InvalidBagtag),
  '409': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser),
  '412': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const BagdropButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')
  const [bagtag, setBagtag] = useState('')
  const bagtagButtonDisabled = bagtag.length < 24

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await registerBagtag(value)
        setRequestStatus('success')
      } catch (error: any) {
        if (error.status !== 400) {
          logError(new Error('Failed to register bagtag'), error)
        }

        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <div className="absolute top-0 left-0 size-full bg-[rgba(255,255,255,0.8)] flex flex-col items-center justify-center text-center">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2 className="mt-5">{intl.formatMessage(NativeTranslations_RegisterBagdrop_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2 className="mt-5">{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </div>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_BAGDROP ? (
    <>
      <button
        className="card shadow-2xl p-0 rounded-full flex flex-col items-center justify-center h-[100px] w-[100px]"
        onClick={toggleCamera}
      >
        <BagIcon color="black" size="3rem" />
        <small className="font-light">{intl.formatMessage(Common_Bagdrop)}</small>
      </button>

      {showCamera && (
        <Modal isOpen onClose={toggleCamera}>
          <div className="drawer">
            {requestStatus !== 'initial' && renderFeedback()}

            <div className="absolute bottom-0 w-full p-3 z-10">
              <input
                className="rounded-lg p-2 bg-white w-full border-none mb-3 opacity-80"
                type="text"
                maxLength={24}
                placeholder={intl.formatMessage(BagdropButton_EnterManually)}
                onChange={evt => requestStatus === 'initial' && setBagtag(evt.target.value)}
              />
              <button
                className="btn w-full disabled:opacity-70"
                aria-disabled={bagtagButtonDisabled}
                disabled={bagtagButtonDisabled}
                onClick={() => onRead(bagtag)}
              >
                {intl.formatMessage(Common_Register)}
              </button>
            </div>

            <CameraScanner
              description={intl.formatMessage(NativeTranslations_RegisterBagdrop_Description)}
              onRead={onRead}
            />
          </div>
        </Modal>
      )}
    </>
  ) : null
}

import React from 'react'
import { type UseMutationResult } from '@tanstack/react-query'
import { intl } from '../../../../lib'
import { Loading } from '../../../shared'
import {
  PayoutSettings_DeleteBankTransferDescription,
  PayoutSettings_BankTransfer_Disclaimer_Three,
  PayoutSettings_DeletePayout,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
  updateQuery: UseMutationResult<any, Error, PayoutUpdateType, unknown>
}

export const Existing = ({ savedConfiguration, active, updateQuery }: Props) => (
  <>
    {!active ? (
      <>
        <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>

        <p>{intl.formatMessage(PayoutSettings_BankTransfer_Disclaimer_Three)}</p>

        <button
          className="btn primary"
          aria-disabled={updateQuery.isPending}
          disabled={updateQuery.isPending}
          onClick={() => updateQuery.mutate({ type: 'BANK_TRANSFER', method: 'PUT' })}
        >
          {updateQuery.isPending ? (
            <Loading />
          ) : (
            <span>
              {intl.formatMessage(Common_UseSavedAccount)}
              <p>
                <small>({savedConfiguration?.maskedAccountNumber})</small>
              </p>
            </span>
          )}
        </button>
      </>
    ) : (
      <>
        <h1 className="text-3xl">{intl.formatMessage(PayoutSettings_DeletePayout)}</h1>
        <p>{intl.formatMessage(PayoutSettings_DeleteBankTransferDescription)}</p>
      </>
    )}

    <button
      className="btn danger"
      aria-disabled={updateQuery.isPending}
      disabled={updateQuery.isPending}
      onClick={() => updateQuery.mutate({ type: 'BANK_TRANSFER', method: 'DELETE' })}
      name="payoutType"
      value="BANK_TRANSFER"
    >
      {updateQuery.isPending ? <Loading /> : intl.formatMessage(Common_DeleteSaved)}
    </button>
  </>
)

import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const QrCodeIcon = (props: Props) => (
  <svg
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0906 21.8177C16.3499 21.8177 18.1815 23.6493 18.1815 25.9086V35.9084C18.1815 38.1677 16.3499 39.9992 14.0906 39.9992H4.09083C1.83153 39.9992 0 38.1677 0 35.9084V25.9086C0 23.6493 1.83153 21.8177 4.09083 21.8177H14.0906ZM27.8807 21.8177V27.8782H33.9395V33.9387L27.8807 33.9377V39.9981H21.8202V33.9377L27.879 33.9387V27.8782H21.8202V21.8177H27.8807ZM40 33.9377V39.9981H33.9395V33.9377H40ZM14.0906 24.545H4.09083C3.33773 24.545 2.72722 25.1555 2.72722 25.9086V35.9084C2.72722 36.6615 3.33773 37.272 4.09083 37.272H14.0906C14.8437 37.272 15.4542 36.6615 15.4542 35.9084V25.9086C15.4542 25.1555 14.8437 24.545 14.0906 24.545ZM11.8179 28.1813V33.6357H6.36351V28.1813H11.8179ZM40 21.8177V27.8782H33.9395V21.8177H40ZM14.0906 0C16.3499 0 18.1815 1.83153 18.1815 4.09083V14.0906C18.1815 16.3499 16.3499 18.1815 14.0906 18.1815H4.09083C1.83153 18.1815 0 16.3499 0 14.0906V4.09083C0 1.83153 1.83153 0 4.09083 0H14.0906ZM35.9084 0C38.1677 0 39.9992 1.83153 39.9992 4.09083V14.0906C39.9992 16.3499 38.1677 18.1815 35.9084 18.1815H25.9086C23.6493 18.1815 21.8177 16.3499 21.8177 14.0906V4.09083C21.8177 1.83153 23.6493 0 25.9086 0H35.9084ZM14.0906 2.72722H4.09083C3.33773 2.72722 2.72722 3.33773 2.72722 4.09083V14.0906C2.72722 14.8437 3.33773 15.4542 4.09083 15.4542H14.0906C14.8437 15.4542 15.4542 14.8437 15.4542 14.0906V4.09083C15.4542 3.33773 14.8437 2.72722 14.0906 2.72722ZM35.9084 2.72722H25.9086C25.1555 2.72722 24.545 3.33773 24.545 4.09083V14.0906C24.545 14.8437 25.1555 15.4542 25.9086 15.4542H35.9084C36.6615 15.4542 37.272 14.8437 37.272 14.0906V4.09083C37.272 3.33773 36.6615 2.72722 35.9084 2.72722ZM11.8179 6.36351V11.8179H6.36351V6.36351H11.8179ZM33.6357 6.36351V11.8179H28.1813V6.36351H33.6357Z"
      fill={props.color || 'black'}
    />
  </svg>
)

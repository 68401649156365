import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../hooks'
import { Loading } from '../shared'
import { intl } from '../../lib'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import {
  Common_Yes,
  Common_EditPayout,
  PayoutSettings_QuickSelect_Confirm_Donation_Description,
  PayoutSettings_QuickSelect_Confirm_Donation_Title,
  PayoutSettings_QuickSelect_Title,
  Common_No
} from '../../translations/messages'

export const PayoutQuickSelect = () => {
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const updateQuery = usePayoutMethodMutation()
  const [showConfirmDonationPayout, setConfirmDonationPayout] = useState<boolean>(false)

  const applicableMethods = payoutMethods?.filter(
    ({ type, savedConfiguration }) => savedConfiguration !== null || type === 'E_VOUCHER'
  )

  return (
    <>
      {showConfirmDonationPayout ? (
        <div className="p-10 flex flex-col space-y-10 max-w-[var(--max-page-width)] mx-auto">
          <h1 className="text-3xl">{intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Title)}</h1>
          <p>{intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Description)}</p>

          <div className="flex space-x-5">
            <button className="btn w-full" onClick={() => setConfirmDonationPayout(false)}>
              {intl.formatMessage(Common_No)}
            </button>

            <button
              className="btn primary w-full"
              aria-label={intl.formatMessage(Common_Yes)}
              onClick={() => {
                setConfirmDonationPayout(false)
                updateQuery.mutate({ type: 'DONATION', method: 'PUT' })
              }}
            >
              {intl.formatMessage(Common_Yes)}
            </button>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col space-y-5 p-5 max-w-[var(--max-page-width)] mx-auto"
          data-testid="PayoutQuickSelect"
        >
          <b>{intl.formatMessage(PayoutSettings_QuickSelect_Title)}</b>

          {applicableMethods?.map(({ type, active, savedConfiguration, logoUrl }: PayoutMethodType) => {
            const { logo, name } = payoutLogoAndTranslationMap[type]
            const isLoading = updateQuery.isPending && updateQuery.variables.type === type

            return (
              <button
                className={`btn flex justify-between items-center leading-4 ${active && 'cursor-not-allowed opacity-60'}`}
                onClick={() =>
                  type === 'DONATION' ? setConfirmDonationPayout(true) : updateQuery.mutate({ type, method: 'PUT' })
                }
                aria-disabled={active}
                disabled={active}
                key={type}
              >
                {logoUrl ? <img src={logoUrl} alt="charity logo" className="h-10" /> : logo}

                <div>
                  <p>{name}</p>
                  {savedConfiguration && (
                    <p>
                      <small className="text-sm">
                        {savedConfiguration?.email ||
                          savedConfiguration?.maskedAccountNumber ||
                          savedConfiguration?.campaignName}
                      </small>
                    </p>
                  )}
                </div>

                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="rounded-full border-2 border-black size-8 relative">
                    {active && (
                      <div className="rounded-full bg-black size-4 absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2" />
                    )}
                  </div>
                )}
              </button>
            )
          })}

          <Link to="../payoutsettings" className="btn ring-2 ring-inset ring-black mt-10 w-full">
            {intl.formatMessage(Common_EditPayout)}
          </Link>
        </div>
      )}
    </>
  )
}

import React, { FunctionComponent } from 'react'

type Props = {
  label: string
  value: string
  valid: boolean
  onChange: (value: string) => void
  maxDate?: string
  required?: boolean
  replaceLabel?: boolean
  replacementLabel?: string
  errorMsg?: string
}

export const DatePickerField: FunctionComponent<Props> = ({ label, value, onChange, maxDate, required, errorMsg }) => {
  return (
    <div className="relative flex flex-col w-full pb-5 pt-4 mt-3">
      <label
        htmlFor={label.toLowerCase()}
        className={`absolute top-1/4 left-0 transition-all cursor-text opacity-60 ${value && '-translate-y-full text-sm opacity-100'}`}
      >
        {label}
      </label>

      <input
        id={label.toLowerCase()}
        className="bg-transparent w-full border-b border-black"
        type="date"
        onChange={({ target: { value } }) => onChange(value)}
        value={value}
        max={maxDate}
        required={required}
        pattern="\d{4}-\d{2}-\d{2}"
      />

      {errorMsg && (
        <p className="absolute w-full top-3/4 left-0 text-warm-red" id={`${label}-error`}>
          {errorMsg}
        </p>
      )}
    </div>
  )
}

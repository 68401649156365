import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { VoucherCard } from './VoucherCard'

type Props = {
  vouchers: VoucherType[]
}

export const VoucherSwiper = ({ vouchers }: Props) => {
  const [emblaRef] = useEmblaCarousel({ loop: vouchers.length === 1 ? true : false })

  return (
    <div ref={emblaRef} className="overflow-hidden">
      <div className="flex mx-10">
        {vouchers.map(voucher => (
          <VoucherCard voucher={voucher} key={voucher.barcode} />
        ))}
      </div>
    </div>
  )
}

import React from 'react'
import { Outlet, useLocation, Link, useParams } from 'react-router-dom'
import { BackButton } from '../shared'
import { intl } from '../../lib'
import {
  AchievementsPage_AllAchievements,
  Common_Achievements,
  AchievementsPage_YourAchievements
} from '../../translations/messages'

export const AchievementsPage = () => {
  const { pathname } = useLocation()
  const { achievementName } = useParams()

  return (
    <div className="animate-fadeIn">
      <BackButton />

      {!achievementName && (
        <div className="flex flex-col items-center w-full bg-gradient-to-tl from-[var(--colors-theme-secondary)] to-[var(--colors-theme-secondary-light)]">
          <div className="max-w-[var(--max-page-width)] mx-auto w-full p-10">
            <h1 className="text-3xl my-10">{intl.formatMessage(Common_Achievements)}</h1>

            <div className="tabs small mb-5">
              <Link to="." className={`${pathname === '/achievements' ? 'active' : ''}`} replace>
                {intl.formatMessage(AchievementsPage_YourAchievements)}
              </Link>
              <Link to="all" className={`${pathname === '/achievements/all' ? 'active' : ''}`} replace>
                {intl.formatMessage(AchievementsPage_AllAchievements)}
              </Link>
            </div>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  )
}

import React from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import { LocationCategory } from './LocationCategory'
import {
  CPTInfo_Title,
  CollectionPointTypes_RVM_Description,
  CollectionPointTypes_DEPOT_Description,
  CollectionPointTypes_OTC_Description,
  CollectionPointTypes_DONATION_STATION_Description
} from '../../translations/messages'
import { InfoIcon } from '../icons'

const Container = styled.div`
  :hover {
    > div {
      display: block;
    }
  }
`

const categoryDescriptionMap = {
  RVM: CollectionPointTypes_RVM_Description,
  DEPOT: CollectionPointTypes_DEPOT_Description,
  OTC: CollectionPointTypes_OTC_Description,
  DONATION_STATION: CollectionPointTypes_DONATION_STATION_Description
}

export const LocationCategoryInfo = ({ locationCategories }: { locationCategories: LocationCategory[] }) => (
  <Container>
    <InfoIcon />

    <div className="card absolute bottom-14 left-1/2 -translate-x-1/2 hidden bg-black text-white p-3 w-3/4 z-10 space-y-5">
      <p>
        <b>{intl.formatMessage(CPTInfo_Title)}</b>
      </p>

      {locationCategories.map(category => (
        <section key={category}>
          <LocationCategory locationCategory={category} />
          <p className="mt-3">{intl.formatMessage(categoryDescriptionMap[category])}</p>
        </section>
      ))}
    </div>
  </Container>
)

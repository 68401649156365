import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LanguagePickerIconButton } from './LanguagePicker'
import { setAndSaveAppUrl } from '../../services'
import { CountryPickerButton } from '../shared'
import { theme, intl, STAGE } from '../../lib'
import { UserIcon, ArrowRightIcon, LockIcon } from '../icons'
import { WelcomePage_Locations, WelcomePage_Subtitle, Auth_Register, Auth_SignIn } from '../../translations/messages'

export const WelcomePage = () => {
  const [easterEggClicks, setEasterEggClicks] = useState(1)

  const onBrandingImageClick = () => {
    setEasterEggClicks(easterEggClicks + 1)

    if (easterEggClicks % 15 === 0) {
      const isTest = STAGE === 'test'

      if (window.confirm(`Switch to ${isTest ? 'prod' : 'test'} environment?`)) {
        setAndSaveAppUrl(isTest ? window.location.host.replace('test.', '') : `test.${window.location.host}`)
      }
    }
  }

  return (
    <div className="flex flex-col justify-between h-[90vh] animate-fadeIn">
      {theme.showCountryPicker && (
        <div className="absolute top-5 left-5">
          <CountryPickerButton />
        </div>
      )}

      <div className="absolute top-5 right-5">
        <LanguagePickerIconButton />
      </div>

      <img className="max-w-36 max-h-22 mt-[10vh] mx-auto" src={theme.images.welcomePageLogo} alt="Tomra logo" />

      {/* eslint-disable-next-line */}
      <img
        className="h-[23vh] mx-auto"
        onClick={onBrandingImageClick}
        src={theme.images.welcomePageBrandingElement}
        alt="welcome"
      />

      <h2>{intl.formatMessage(WelcomePage_Subtitle)}</h2>

      <div className="flex flex-col space-y-5">
        <Link to="/login/register" className="btn !justify-between">
          <UserIcon />
          {intl.formatMessage(Auth_Register)}
          <ArrowRightIcon />
        </Link>

        <Link to="/login/signin" className="btn !justify-between">
          <LockIcon />
          {intl.formatMessage(Auth_SignIn)}
          <ArrowRightIcon />
        </Link>
      </div>

      <Link to="/locations" className="text-center text-black underline">
        {intl.formatMessage(WelcomePage_Locations)}
      </Link>
    </div>
  )
}

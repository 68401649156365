import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BackButton, Loading } from '../../shared'
import { calculateDaysBetween, intl } from '../../../lib'
import { CharityInfoDrawer } from './CharityInfoDrawer'
import { CampaignProgressBar } from './CampaignProgressBar'
import { benefitToTranslationMap } from '../common'
import { useCharityCampaignQuery } from '../../../hooks'
import {
  CampaignInfo_DaysLeft,
  CampaignInfo_GoalMet,
  Common_CampaignEnded,
  Common_By,
  Common_Description
} from '../../../translations/messages'
import { SetupCampaignPayout } from './SetupCampaignPayout'

export const CampaignInfo = () => {
  const [showCharityInfoDrawer, setShowCharityInfoDrawer] = useState(false)
  const { campaignId } = useParams()
  const { data: campaign } = useCharityCampaignQuery(campaignId!)

  if (!campaign) {
    return <Loading className="centerAbsolute" />
  }

  const {
    charityBenefit,
    charityLogoUrl,
    endTime,
    name,
    charityDisplayName,
    charityWebsiteUrl,
    description,
    charityABN,
    donatedTotal,
    financialGoal,
    charityDescription,
    status
  } = campaign

  const completionPercentage = Math.floor((donatedTotal / financialGoal) * 100)
  const financialGoalIsMet = completionPercentage >= 100
  const daysLeft = calculateDaysBetween(Date.now(), endTime)
  const campaignHasEnded = daysLeft < 1 || status === 'STOPPED'

  return (
    <>
      <BackButton to=".." />

      <CharityInfoDrawer
        onClose={() => setShowCharityInfoDrawer(false)}
        visible={showCharityInfoDrawer}
        charityDisplayName={charityDisplayName}
        charityABN={charityABN}
        charityDescription={charityDescription}
        charityWebsiteUrl={charityWebsiteUrl}
      />

      <div className="bg-gradient-to-tl from-[var(--colors-theme-tertiary-light)] to-white p-10 relative">
        <div className="flex flex-col items-center space-y-5 max-w-[var(--max-page-width)] mx-auto">
          <small className="absolute top-5 right-5 font-bold">
            {intl.formatMessage(benefitToTranslationMap[charityBenefit])}
          </small>

          <button className="btn-icon" onClick={() => setShowCharityInfoDrawer(true)}>
            <img
              src={charityLogoUrl}
              alt="charity campaign logo"
              className="object-cover rounded-full border-2 border-[var(--colors-theme-tertiary)] w-36 h-36 bg-white cursor-pointer"
            />
          </button>

          {campaignHasEnded ? (
            <p style={{ color: 'var(--colors-red)' }}>{intl.formatMessage(Common_CampaignEnded)}</p>
          ) : (
            <p>{intl.formatMessage(CampaignInfo_DaysLeft, { daysLeft })}</p>
          )}

          <h1 className="text-3xl">{name}</h1>

          <small className="flex items-center space-x-1">
            <p>{intl.formatMessage(Common_By)}</p>
            <button className="btn-icon underline" onClick={() => setShowCharityInfoDrawer(true)}>
              {charityDisplayName}
            </button>
          </small>

          <CampaignProgressBar
            percentage={completionPercentage}
            donatedTotal={donatedTotal}
            financialGoal={financialGoal}
          />
        </div>
      </div>

      <div className="max-w-[var(--max-page-width)] mx-auto space-y-5 p-5">
        {financialGoalIsMet && (
          <div className="alert success">
            <p>{intl.formatMessage(CampaignInfo_GoalMet)}</p>
          </div>
        )}

        <h2 className="text-xl">{intl.formatMessage(Common_Description)}</h2>

        <p>{description}</p>

        {!campaignHasEnded && campaignId && (
          <SetupCampaignPayout charityDisplayName={charityDisplayName} campaignName={name} />
        )}
      </div>
    </>
  )
}

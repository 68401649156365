import React from 'react'
import { useLocation } from 'react-router-dom'
import { BackButton } from '../shared'
import { EnvelopeIcon } from '../icons'
import { openBrowserWindow } from '../../services'
import { intl, countryStateMap, VIEWER_COUNTRY_STATE } from '../../lib'
import {
  Auth_EmailLinkSentAt,
  Auth_OpenEmailAndTapToSignIn,
  Auth_DidntReceiveLink,
  Auth_CheckSpamFolder,
  Auth_CheckSpellingOfEmail,
  Auth_ContactSupport,
  Auth_WaitTwoMinutes,
  Auth_RegisterDisclaimer,
  Auth_LoginDisclaimer,
  Common_CheckEmail
} from '../../translations/messages'

export const MailSentPage = () => {
  const location = useLocation()
  const { contactInfo } = countryStateMap[VIEWER_COUNTRY_STATE]

  return (
    <div className="animate-fadeIn">
      <BackButton to="/login" />

      <div className="flex flex-col justify-around min-h-[90vh] space-y-10 mt-5">
        <EnvelopeIcon className="animate-pulse centerMargin" size="5rem" />

        <h1 className="text-3xl">{intl.formatMessage(Common_CheckEmail)}</h1>

        <p>{intl.formatMessage(Auth_EmailLinkSentAt)}</p>
        <h2 className="font-bold text-xl">{location.state?.email}</h2>

        <p>{intl.formatMessage(Auth_OpenEmailAndTapToSignIn)}</p>

        <p>{intl.formatMessage(Auth_DidntReceiveLink)}</p>

        <div>
          <ul className="list-disc list-inside">
            <li className="font-bold">
              {intl.formatMessage(location.state?.isRegisterFlow ? Auth_RegisterDisclaimer : Auth_LoginDisclaimer)}
            </li>
            <li>{intl.formatMessage(Auth_WaitTwoMinutes)}</li>
            <li>{intl.formatMessage(Auth_CheckSpamFolder)}</li>
            <li>{intl.formatMessage(Auth_CheckSpellingOfEmail)}</li>
            <li>
              {intl.formatMessage(Auth_ContactSupport)}:
              <button
                className="btn-icon underline"
                style={{ marginLeft: 'var(--spacing-xs)' }}
                onClick={() => openBrowserWindow(contactInfo.loginHelpUrl)}
              >
                {contactInfo.loginHelpUrl}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

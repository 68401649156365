import React from 'react'
import { openBrowserWindow } from '../../services'
import { BackButton } from '../shared'
import { EmailIcon, ArrowRightIcon, FacebookIcon, PhoneIcon } from '../icons'
import { countryStateMap, VIEWER_COUNTRY_STATE, theme, intl } from '../../lib'
import {
  Common_ContactUs,
  ContactUs_Description,
  ContactUs_FindUsOnFacebook,
  ContactUs_NotAvailable
} from '../../translations/messages'

export const ContactUs = () => {
  const { name, contactInfo } = countryStateMap[VIEWER_COUNTRY_STATE]
  const { email, phone, facebookUrl } = contactInfo
  const hasContactInfo = email || phone || facebookUrl

  return (
    <>
      <BackButton to="/" />

      <div className="mx-auto max-w-[var(--max-page-width)] space-y-10 p-5">
        <h1 className="text-3xl mt-20">{intl.formatMessage(Common_ContactUs)}</h1>

        <p>
          {hasContactInfo
            ? intl.formatMessage(ContactUs_Description)
            : intl.formatMessage(ContactUs_NotAvailable, { appName: theme.name, countryStateName: name })}
        </p>

        <div className="flex flex-col space-y-5">
          {email && (
            <a href={`mailto:${email}`} className="btn !justify-between">
              <EmailIcon />
              <span>{email}</span>
              <ArrowRightIcon />
            </a>
          )}

          {phone && (
            <a href={`tel:${phone}`} className="btn !justify-between">
              <PhoneIcon />
              <span>{phone}</span>
              <ArrowRightIcon />
            </a>
          )}

          {facebookUrl && (
            <button
              className="btn flex items-center justify-between w-full"
              onClick={() => openBrowserWindow(facebookUrl)}
            >
              <FacebookIcon />
              <span>{intl.formatMessage(ContactUs_FindUsOnFacebook)}</span>
              <ArrowRightIcon />
            </button>
          )}
        </div>
      </div>
    </>
  )
}

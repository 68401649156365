import React from 'react'
import { Currency } from '../../shared'
import { StarIcon } from '../../icons'
import { intl } from '../../../lib'
import { CampaignInfo_RaisedSoFar, CampaignInfo_Goal } from '../../../translations/messages'

type Props = {
  percentage: number
  donatedTotal: number
  financialGoal: number
}

export const CampaignProgressBar = ({ percentage, donatedTotal, financialGoal }: Props) => {
  const normalizedPercentage = percentage > 100 ? 100 : percentage

  return (
    <div className="w-full space-y-5">
      <div className="relative rounded-full bg-transparent border-2 border-black">
        <div
          style={{ width: `${normalizedPercentage}%` }}
          className="bg-[var(--colors-theme-tertiary)] h-3 rounded-full"
        />

        {normalizedPercentage === 100 && (
          <div data-testid="progressbar-stars" className="animate-pulse absolute top-1/2 -right-5 -translate-y-1/2">
            <div className="relative -left-3 bottom-2">
              <StarIcon fillColor="var(--colors-theme-tertiary)" size="0.8rem" />
            </div>
            <div className="relative bottom-3">
              <StarIcon fillColor="var(--colors-theme-tertiary)" size="1rem" />
            </div>
            <div className="relative bottom-2">
              <StarIcon fillColor="var(--colors-theme-tertiary)" size="0.8rem" />
            </div>
          </div>
        )}
      </div>

      <div className="text-center flex items-center justify-between">
        <div>
          <p>{intl.formatMessage(CampaignInfo_RaisedSoFar)}</p>
          <Currency amount={donatedTotal} />
        </div>

        <div>
          <p>{intl.formatMessage(CampaignInfo_Goal)}</p>
          <Currency amount={financialGoal} />
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Modal } from './shared'
import { isOnPwa } from '../services'
import { pushMessage } from './AppMessages'
import { theme, setItemInWebStorage, getItemFromWebStorage, intl } from '../lib'
import {
  InstallAppDrawer_Title,
  InstallAppDrawer_Description,
  InstallAppDrawer_Success,
  Common_Yes,
  Common_No
} from '../translations/messages'

export const InstallAppDrawer = () => {
  const [visible, setVisible] = useState(false)
  const [installEvent, setInstallEvent] = useState<any | null>(null)

  const closeDrawerForGood = () => {
    setItemInWebStorage('HAS_SHOWN_PWA_PROMPT', true)
    setVisible(false)
  }

  const promptInstall = async () => {
    if (installEvent && installEvent.prompt) {
      try {
        const result = await installEvent.prompt()

        if (result?.accepted) {
          pushMessage({
            formattedMessage: intl.formatMessage(InstallAppDrawer_Success),
            type: 'success',
            ttl: 5000
          })
        }

        closeDrawerForGood()
      } catch (error) {
        closeDrawerForGood()
      }
    }
  }

  useEffect(() => {
    // This will trigger upon desktop -> standalone, i.e after a desktop install
    window.matchMedia('(display-mode: standalone)').addListener(event => {
      if (event.matches) {
        closeDrawerForGood()
      }
    })

    const handleInstallPrompt = async event => {
      event.preventDefault()

      const hasShownPrompt = await getItemFromWebStorage('HAS_SHOWN_PWA_PROMPT')

      if (!isOnPwa() && !hasShownPrompt) {
        setVisible(true)
      }

      setInstallEvent(event)
    }

    window.addEventListener('beforeinstallprompt', handleInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt)
    }
  }, [])

  return (
    <Modal isOpen={visible}>
      <div className="drawer">
        <div className="max-w-[var(--max-page-width)] mx-auto space-y-5 p-10 flex flex-col">
          <h1 className="text-3xl">{intl.formatMessage(InstallAppDrawer_Title)}</h1>

          <p>{intl.formatMessage(InstallAppDrawer_Description, { appName: theme.name })}</p>

          <button className="btn primary" onClick={promptInstall}>
            {intl.formatMessage(Common_Yes)}
          </button>

          <button className="btn" onClick={closeDrawerForGood}>
            {intl.formatMessage(Common_No)}
          </button>
        </div>
      </div>
    </Modal>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { intl } from '../../../lib'
import { LocationIcon } from '../../icons'
import { FindLocation_ButtonText } from '../../../translations/messages'

export const FindLocation = () => (
  <Link to="/locations" className="active">
    <LocationIcon />
    <p>{intl.formatMessage(FindLocation_ButtonText)}</p>
  </Link>
)

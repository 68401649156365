import React, { useState } from 'react'
import { TextField_InvalidInput } from '../../../translations/messages'
import { intl, debounce } from '../../../lib'

type Props = {
  type: string
  label: string
  value: string
  onChange: (value: string, valid: boolean) => void
  pattern?: string
  required?: boolean
  patternErrorMsg?: string
  children?: React.ReactNode
  classNames?: string
  autoComplete?: string
  ignoreWhitespace?: boolean
}

export const TextField = (props: Props) => {
  const [errorMsg, setErrorMsg] = useState('')

  const validateAndUpdate = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let error

    debounce(() => {
      const validity = evt.target.validity
      const defaultError = evt.target.validationMessage

      if (validity.patternMismatch) {
        error = props.patternErrorMsg || intl.formatMessage(TextField_InvalidInput)
      } else {
        error = defaultError
      }

      setErrorMsg(error)
    })

    props.onChange(evt.target.value, evt.target.validity.valid)
  }

  return (
    <div className="input-field relative" data-error={errorMsg}>
      <label className="label text-black" htmlFor={props.label}>
        {props.label}
      </label>

      <input
        id={props.label}
        type={props.type}
        autoComplete={props.autoComplete}
        className={`input-text ${props.classNames || ''} ${errorMsg ? 'invalid' : ''}`}
        value={props.value}
        pattern={props.pattern}
        onKeyDown={evt => {
          if (evt.key === ' ' && props.ignoreWhitespace) evt.preventDefault()
        }}
        onChange={validateAndUpdate}
        required={props.required}
      />

      {props.children}

      <div className="sr-only">{errorMsg}</div>
    </div>
  )
}

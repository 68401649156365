import React from 'react'
import { intl } from '../../../../lib'
import {
  Common_Continue,
  PayoutSettings_BankTransfer_Disclaimer_One,
  PayoutSettings_BankTransfer_Disclaimer_Two,
  Common_EditPayout
} from '../../../../translations/messages'

type Props = {
  setStage: (stage: 'existing' | 'setup' | 'disclaimer') => void
}

export const Disclaimer = ({ setStage }: Props) => (
  <>
    <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>
    <p>{intl.formatMessage(PayoutSettings_BankTransfer_Disclaimer_One)}</p>
    <p>{intl.formatMessage(PayoutSettings_BankTransfer_Disclaimer_Two)}</p>
    <button className="btn" onClick={() => setStage('setup')}>
      {intl.formatMessage(Common_Continue)}
    </button>
  </>
)

import React, { useState, useEffect } from 'react'
import { type UseMutationResult } from '@tanstack/react-query'
import { Existing } from './Existing'
import { Disclaimer } from './Disclaimer'
import { Setup } from './Setup'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
  updateQuery: UseMutationResult<any, Error, PayoutUpdateType, unknown>
}

export const BankTransfer = ({ savedConfiguration, active, updateQuery }: Props) => {
  const [stage, setStage] = useState<'existing' | 'setup' | 'disclaimer'>(
    savedConfiguration ? 'existing' : 'disclaimer'
  )

  useEffect(() => {
    setStage(savedConfiguration ? 'existing' : 'disclaimer')
  }, [savedConfiguration])

  if (stage === 'existing') {
    return <Existing savedConfiguration={savedConfiguration} active={active} updateQuery={updateQuery} />
  }

  if (stage === 'disclaimer') {
    return <Disclaimer setStage={setStage} />
  }

  return <Setup />
}

import React, { useState, useEffect } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { intl } from '../../lib'
import { fetchMarketingsubscription, updateMarketingsubscription } from '../../services'
import { pushMessage } from '../AppMessages'
import {
  Common_SubscribeToNewsletter,
  NewsLetterSetting_Subscribed,
  NewsLetterSetting_Unsubscribed,
  NewsLetterSetting_UpdateFailed
} from '../../translations/messages'

export const NewsLetterSetting = () => {
  const [subscribed, setSubscribed] = useState<'SUBSCRIBED' | 'UNSUBSCRIBED'>()

  useEffect(() => {
    const f = fetchMarketingsubscription()
    f.run()
      .then(({ status }) => setSubscribed(status))
      .catch(() => setSubscribed('UNSUBSCRIBED'))

    return () => {
      f.abort()
    }
  }, [])

  const setMarketingSubscription = () => {
    const oldStatus = subscribed
    const newStatus = subscribed === 'SUBSCRIBED' ? 'UNSUBSCRIBED' : 'SUBSCRIBED'

    setSubscribed(newStatus)

    updateMarketingsubscription(newStatus)
      .run()
      .then(
        () => {
          pushMessage({
            formattedMessage:
              newStatus === 'SUBSCRIBED'
                ? intl.formatMessage(NewsLetterSetting_Subscribed)
                : intl.formatMessage(NewsLetterSetting_Unsubscribed),
            type: 'success',
            ttl: 5000
          })
        },
        error => {
          setSubscribed(oldStatus)
          pushMessage({
            formattedMessage: intl.formatMessage(NewsLetterSetting_UpdateFailed),
            type: 'danger',
            ttl: 5000
          })
          logError(new Error('Failed to update marketingsubscription'), error)
        }
      )
  }

  return (
    <div className={`flex p-6 cursor-${!subscribed ? 'not-allowed' : 'pointer'} opacity-${!subscribed ? 50 : 1}`}>
      <input
        id="newsletterCheckbox"
        type="checkbox"
        className="checkbox min-w-[1.3rem]"
        aria-label="Subscribe to newsletter"
        aria-disabled={!subscribed}
        onChange={setMarketingSubscription}
        checked={subscribed === 'SUBSCRIBED'}
      />

      <label className="ml-5 cursor-pointer" htmlFor="newsletterCheckbox">
        {intl.formatMessage(Common_SubscribeToNewsletter)}
      </label>
    </div>
  )
}

import React from 'react'
import { InfoIcon } from '../../icons'

type Props = {
  content: string
  position?: 'left' | 'right'
}

export const InfoTooltip = ({ content, position }: Props) => (
  <div className={`tooltip ${position || ''}`} data-tip={content}>
    <InfoIcon />
  </div>
)

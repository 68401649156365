import React from 'react'
import { type UseMutationResult } from '@tanstack/react-query'
import { intl } from '../../../lib'
import { Loading } from '../../shared'
import {
  PayoutSettings_Voucher_Description,
  PayoutSettings_Voucher_Disclaimer,
  Common_EditPayout,
  Common_Enable
} from '../../../translations/messages'

type Props = {
  updateQuery: UseMutationResult<any, Error, PayoutUpdateType, unknown>
}

export const Voucher = ({ updateQuery }: Props) => {
  return (
    <>
      <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>
      <span>{intl.formatMessage(PayoutSettings_Voucher_Description)}</span>
      <span>{intl.formatMessage(PayoutSettings_Voucher_Disclaimer)}</span>

      <button
        className="btn primary"
        disabled={updateQuery.isPending}
        onClick={() => updateQuery.mutate({ type: 'E_VOUCHER', method: 'PUT' })}
      >
        {updateQuery.isPending ? <Loading /> : intl.formatMessage(Common_Enable)}
      </button>
    </>
  )
}

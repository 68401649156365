import React from 'react'
import { theme, intl } from '../../lib'
import {
  CollectionPointTypes_RVM_Title,
  CollectionPointTypes_DEPOT_Title,
  CollectionPointTypes_OTC_Title,
  CollectionPointTypes_DONATION_STATION_Title
} from '../../translations/messages'

const categoryIconAndTitleMap = {
  RVM: {
    icon: <img src={theme.images.locationCategory.RVM.icon} alt="RVM icon" className="max-w-8" />,
    title: intl.formatMessage(CollectionPointTypes_RVM_Title)
  },
  DEPOT: {
    icon: <img src={theme.images.locationCategory.DEPOT.icon} alt="DEPOT icon" className="max-w-8" />,
    title: intl.formatMessage(CollectionPointTypes_DEPOT_Title)
  },
  OTC: {
    icon: <img src={theme.images.locationCategory.OTC.icon} alt="OTC icon" className="max-w-8" />,
    title: intl.formatMessage(CollectionPointTypes_OTC_Title)
  },
  DONATION_STATION: {
    icon: (
      <img src={theme.images.locationCategory.DONATION_STATION.icon} alt="DONATION_STATION icon" className="max-w-8" />
    ),
    title: intl.formatMessage(CollectionPointTypes_DONATION_STATION_Title)
  }
}

type Props = {
  locationCategory: MapLocationType['category']
}

export const LocationCategory = ({ locationCategory }: Props) => {
  const { icon, title } = categoryIconAndTitleMap[locationCategory]

  return (
    <div className="flex items-center space-x-3">
      {icon}
      <b>{title}</b>
    </div>
  )
}

import React, { useState, useContext } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { TextField, Loading } from '../shared'
import { LocationFilter } from './LocationFilter'
import { LocationsContext } from './LocationsContext'
import { pushMessage, removeMessage } from '../AppMessages'
import { findCoordinatesForKeyword } from '../../services'
import {
  LocationSearch_TextFieldLabel,
  LocationSearch_SubmitSearchButtonText,
  LocationSearch_Title,
  LocationSearch_ErrorMessage,
  LocationSearch_NumberOfResults,
  LocationSearch_NoResultsDisclaimer,
  LocationList_Subtitle,
  LocationSearch_NoLocationsInViewport
} from '../../translations/messages'
import { intl } from '../../lib'

type Props = {
  closeDrawer: () => void
}

export const LocationSearch = ({ closeDrawer }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchValid, setSearchValid] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'noResults'>('initial')
  const { mapInstance, getLocationsInViewport, mapSdk } = useContext(LocationsContext)

  const performSearch = async (evt: React.FormEvent) => {
    evt.preventDefault()
    removeMessage()

    setRequestStatus('loading')

    try {
      const { result, status } = await findCoordinatesForKeyword(
        mapSdk!,
        searchValue,
        mapInstance?.getBounds() as google.maps.LatLngBounds
      )

      if (!result || result.length === 0) {
        return setRequestStatus('noResults')
      } else if (status !== 'OK') {
        if (status === 'ZERO_RESULTS') {
          return setRequestStatus('noResults')
        } else {
          throw new Error(status)
        }
      }

      const { location, viewport } = result[0].geometry
      mapInstance?.setCenter(location)
      mapInstance?.fitBounds(new google.maps.LatLngBounds(viewport.getSouthWest(), viewport.getNorthEast()))

      const numberOfLocations = getLocationsInViewport().length

      if (numberOfLocations !== 0) {
        pushMessage({
          type: 'success',
          formattedMessage: intl.formatMessage(LocationList_Subtitle, { numberOfLocations: String(numberOfLocations) }),
          ttl: 5000
        })
      } else {
        pushMessage({
          type: 'warning',
          formattedMessage: intl.formatMessage(LocationSearch_NoLocationsInViewport),
          ttl: 5000
        })
      }

      setRequestStatus('success')
      closeDrawer()
    } catch (error: any) {
      setRequestStatus('failed')
      logError(new Error('Failed to perform geocoding search for location'), error)
    }
  }

  return (
    <form onSubmit={performSearch} className="flex flex-col space-y-5 py-10 px-5 max-w-[var(--max-page-width)] mx-auto">
      <h1 className="text-3xl ml-3">{intl.formatMessage(LocationSearch_Title)}</h1>

      <TextField
        onChange={(value, valid) => {
          if (value !== searchValue) setRequestStatus('initial')
          setSearchValue(value)
          setSearchValid(valid)
        }}
        autoComplete="off"
        value={searchValue}
        label={intl.formatMessage(LocationSearch_TextFieldLabel)}
        type="text"
        required
      />

      {requestStatus === 'failed' && (
        <div className="alert danger">{intl.formatMessage(LocationSearch_ErrorMessage)}</div>
      )}

      {requestStatus === 'noResults' && (
        <div className="-mt-4">
          <div className="alert danger">
            {intl.formatMessage(LocationSearch_NumberOfResults, { numberOfResults: 0, searchValue })}
            <br />
            <small>{intl.formatMessage(LocationSearch_NoResultsDisclaimer)}</small>
          </div>
        </div>
      )}

      <LocationFilter />

      <button className="btn primary" aria-label="Perform search" disabled={!searchValid} aria-disabled={!searchValid}>
        {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(LocationSearch_SubmitSearchButtonText)}
      </button>
    </form>
  )
}

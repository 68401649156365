import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const BagIcon = (props: Props) => (
  <svg
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 40 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8983 2.93038L17.1017 2.93038C16.4577 2.93038 16.0551 3.74437 16.3771 4.39557L19.2755 10.2563C19.5975 10.9075 20.4026 10.9075 20.7246 10.2563L23.6229 4.39557C23.9449 3.74437 23.5424 2.93038 22.8983 2.93038ZM17.1017 11.7215C18.3899 14.3263 21.6102 14.3263 22.8983 11.7215L25.7966 5.86076C27.0848 3.25598 25.4746 2.7936e-06 22.8983 2.56961e-06L17.1017 2.06561e-06C14.5255 1.84161e-06 12.9153 3.25598 14.2034 5.86076L17.1017 11.7215Z"
      fill={props.color || 'black'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37 37.7752V35.2929C37 25.7055 30.6301 17.1995 21.3903 14.386C20.1782 14.017 18.8737 14.025 17.6753 14.4086C8.92953 17.2082 3 25.3015 3 34.4391V37.6689C3 41.9937 6.52508 45.4996 10.8735 45.4996H29.2333C33.5227 45.4996 37 42.0412 37 37.7752ZM16.7562 11.5684C6.77029 14.765 0 24.0058 0 34.4391V37.6689C0 43.6415 4.86823 48.4833 10.8735 48.4833H29.2333C35.1796 48.4833 40 43.6891 40 37.7752V35.2929C40 24.3825 32.758 14.7271 22.2685 11.5331C20.4742 10.9867 18.5424 10.9966 16.7562 11.5684Z"
      fill={props.color || 'black'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5015 9.70024C14.6731 9.70024 14.0015 10.3682 14.0015 11.1921C14.0015 12.016 14.6731 12.6839 15.5015 12.6839H24.5015C25.33 12.6839 26.0015 12.016 26.0015 11.1921C26.0015 10.3682 25.33 9.70024 24.5015 9.70024H15.5015Z"
      fill={props.color || 'black'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5552 11.8544C21.0147 12.159 21.1389 12.7765 20.8325 13.2336L16.8325 19.2009C16.5262 19.6579 15.9053 19.7814 15.4458 19.4767C14.9862 19.1721 14.8621 18.5546 15.1684 18.0975L19.1684 12.1302C19.4748 11.6732 20.0956 11.5497 20.5552 11.8544Z"
      fill={props.color || 'black'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5014 10.8225C19.981 10.55 20.5918 10.7157 20.8658 11.1926L24.8658 18.1545C25.1398 18.6314 24.9732 19.2389 24.4937 19.5114C24.0142 19.7839 23.4034 19.6183 23.1293 19.1413L19.1293 12.1795C18.8553 11.7025 19.0219 11.095 19.5014 10.8225Z"
      fill={props.color || 'black'}
    />
  </svg>
)

import React, { useState } from 'react'
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import { Loading, TextField } from '../shared'
import { intl, FIREBASE } from '../../lib'
import { pushMessage } from '../AppMessages'
import { feedbackMessages } from '../login/feedbackMessages'
import {
  Common_ChangePassword,
  Common_Update,
  Auth_OldPassword,
  Auth_NewPassword,
  Auth_NewPasswordVerify,
  Auth_PasswordRequirements,
  Auth_PasswordsMustMatch,
  Auth_PasswordWasUpdated
} from '../../translations/messages'

export const ChangePassword = ({ email, closeDrawer }: { email: string; closeDrawer: () => void }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [verifyNewPassword, setVerifyNewPassword] = useState('')

  const onSubmit = async (evt: React.SyntheticEvent) => {
    evt.preventDefault()

    if (newPassword !== verifyNewPassword) {
      pushMessage({
        formattedMessage: intl.formatMessage(Auth_PasswordsMustMatch),
        type: 'warning',
        ttl: 5000
      })
      return
    }

    try {
      setRequestStatus('loading')
      const { user } = await signInWithEmailAndPassword(FIREBASE.auth, email, oldPassword)
      updatePassword(user, newPassword)
      setRequestStatus('success')
      pushMessage({
        formattedMessage: intl.formatMessage(Auth_PasswordWasUpdated),
        type: 'success',
        ttl: 5000
      })
      closeDrawer()
    } catch (error: any) {
      setRequestStatus('failed')
      pushMessage({
        formattedMessage: feedbackMessages[error.code] || feedbackMessages.failed,
        type: 'danger',
        ttl: 5000
      })
    }
  }

  return (
    <div className="max-w-[var(--max-page-width)] p-10 mx-auto">
      <h1 className="text-3xl mb-7">{intl.formatMessage(Common_ChangePassword)}</h1>

      <form className="flex flex-col" onSubmit={onSubmit}>
        <TextField
          onChange={value => setOldPassword(value)}
          value={oldPassword}
          label={intl.formatMessage(Auth_OldPassword)}
          type="password"
          required
        ></TextField>

        <TextField
          onChange={value => {
            setNewPassword(value)
          }}
          value={newPassword}
          label={intl.formatMessage(Auth_NewPassword)}
          patternErrorMsg={intl.formatMessage(Auth_PasswordRequirements)}
          type="password"
          pattern=".{8,}"
          required
          ignoreWhitespace
        />

        <TextField
          onChange={value => {
            setVerifyNewPassword(value)
          }}
          value={verifyNewPassword}
          label={intl.formatMessage(Auth_NewPasswordVerify)}
          patternErrorMsg={intl.formatMessage(Auth_PasswordRequirements)}
          type="password"
          pattern=".{8,}"
          required
          ignoreWhitespace
        />

        <button
          className="btn mt-5"
          type="submit"
          disabled={!(oldPassword || newPassword || verifyNewPassword) || requestStatus === 'loading'}
        >
          {requestStatus === 'loading' ? (
            <span data-testid="loading">
              <Loading />
            </span>
          ) : (
            intl.formatMessage(Common_Update)
          )}
        </button>
      </form>
    </div>
  )
}
